html,
body
    height: 100%
    scroll-behavior: smooth

body
    background: $white

b, strong
    font-weight: $font-weight-bold

.bi
    fill: currentColor

.active
    font-weight: 600

.modal-open
    .header,
    .main,
    .footer
        filter: blur(3px)

.no-border
    border: none

.footer
    margin-top: 100px
    p 
        padding: 15px 0
        font:
            size: 85%
            weight: $font-weight-medium
    a
        text-decoration: none
        color: inherit
        font-weight: $font-weight-bold

.hearth
    display: inline-block
    margin: 0 2px
    animation: pulse 1s infinite

@keyframes pulse
    10%
        transform: scale(1.2)
.btn
    font-weight: $font-weight-bold
    &-primary
        color: $white
    &-secondary,
    &-secondary:hover
        color: $cyan

.header
    .navbar
        background: $white
        padding: 10px 0
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)
        @include media-breakpoint-up(lg)
            padding: 15px 0
    .btn
        min-width: 145px
        font-size: 14px
    .nav-item
        position: relative
        margin: 0 15px
        .nav-link
            white-space: nowrap
            color: $primary
            opacity: 0.5
            transition: $transition-base
            font:
                size: 19px
                weight: $font-weight-normal
            &:hover
                opacity: 1
            &.active,
            &.login
                opacity: 1
                font-weight: $font-weight-bold
                &:after
                    width: 60px
            &:after
                display: none
                content: ''
                width: 0
                height: 2px
                background: $primary
                margin: 0 auto
                border-radius: 2px
                @include media-breakpoint-up(lg)
                    display: block

.hero
    position: relative
    overflow: hidden
    color: $white
    height: calc(100vh - 75px)
    margin-top: 75px
    @include media-breakpoint-up(lg)
        height: calc(100vh - 85px)
        margin-top: 85px
    .container,
    .wrapper
        height: 100%
    .wrapper
        display: flex
        justify-content: center
        flex-wrap: wrap
        align-items: flex-start
        flex-direction: column
        height: 100%
        position: relative
        z-index: 5
        margin-right: auto
        @include media-breakpoint-up(lg)
            max-width: 75%
    .display
        margin: 0 0 10px 0
        line-height: 35px
        font:
            size: 30px
            weight: $font-weight-bold
        @include media-breakpoint-up(lg)
            line-height: 65px
            font:
                size: 60px
    .lead
        font-size: 14px
        line-height: 20px
        margin: 0 0 30px 0
        @include media-breakpoint-up(lg)
            font-size: 18px
            line-height: 25px
    .btn
        width: 250px
    video
        position: absolute
        top: 50%
        left: 0
        transform: translateY(-50%)
        z-index: 0
        max-width: 100%
        min-height: 100%
        object-fit: cover
        filter: saturate(1.5)

.section
    padding-top: 80px
    @include media-breakpoint-up(lg)
        padding-top: 120px

.head
    @include media-breakpoint-up(lg)
        padding-right: 10% 

.title
    color: $cyan-light
    text-transform: uppercase
    letter-spacing: 0.18em
    font:
        size: 16px
        weight: $font-weight-bold
    @include media-breakpoint-up(lg)
        font-size: 20px

.description
    letter-spacing: -0.01em
    color: $gray-800
    line-height: 30px
    font:
        size: 24px
        weight: $font-weight-bold
    @include media-breakpoint-up(lg)
        font-size: 36px
        line-height: 44px
        margin-bottom: 20px

.text
    margin-bottom: 20px
    color: $gray-900
    line-height: 24px
    font:
        size: 18px
        weight: $font-weight-normal
    @include media-breakpoint-up(lg)
        margin-bottom: 60px

.hide
    display: none

.agents
    display: none
    @include media-breakpoint-up(lg)
        display: block
    .description
        max-width: 800px
        margin: 0 auto
        text-align: center
        color: $cyan
        strong
            color: $cyan-light
    .timeline
        position: relative
        display: flex
        align-items: flex-end
        margin: -100px auto 90px
        width: 760px
        min-height: 700px 
        background: url('../images/computer.png') center top no-repeat
        .swiper-wrapper
            height: 200px
        .menu
            position: absolute
            // border: 1px solid red
            top: 238px
            width: 320px
            .menu-item
                // border: 1px solid green
                text-decoration: none
                display: flex
                justify-content: flex-end
                align-items: center
                color: $gray-800
                line-height: 25px
                white-space: nowrap
                font:
                    size: 20px
                    weight: $font-weight-bold
                img
                    height: 50px
                    margin-right: 10px
                    // position: absolute
            .swiper-slide
                opacity: 0.35
                transition: $transition-base
                transform: scale(0.8)
                &-active
                    opacity: 1
                    transform: scale(1)
            &.left
                left: -245px
            &.right
                right: -245px
                .menu-item
                    justify-content: flex-end
                    flex-direction: row-reverse
                    img
                        margin-right: 0
                        margin-left: 10px
        .content
            position: relative
            width: 100%
            .close
                position: absolute
                top: 10px
                right: 10px
                cursor: pointer
            .message
                min-height: 265px
                position: relative
                margin-top: auto
                padding: 35px 60px
                background: linear-gradient(180deg, #EBF5FF 0%, #FFFFFF 100%)
                border-radius: 20px
                width: 620px
                max-width: 90%
                margin: 0 auto -90px
                h3
                    color: #003060
                    margin-bottom: 15px
                    line-height: 44px
                    font:
                        size: 36px
                        weight: $font-weight-bold
                p
                    line-height: 24px
                    color: $gray-800
                    font:
                        size: 18px
                        weight: $font-weight-normal


.highlight
    font-weight: $font-weight-bold
    color: $cyan-light

.block
    margin-top: 20px
    .label
        margin-bottom: 10px
        font:
            size: 24px
            weight: $font-weight-bold
        color: $primary
    .text
        font-size: 16px
        line-height: 23px
        margin-bottom: 15px
    img
        width: 100%
        margin-bottom: 20px
        border-radius: 10px 10px 0 0

.box
    position: relative
    text-align: center
    padding: 30px 25px
    margin-bottom: 40px
    margin-right: auto
    border-radius: 10px 10px 0 0
    @include media-breakpoint-up(lg)
        padding: 60px 45px
        max-width: 95%
        margin-bottom: 90px
    .right &
        margin-left: auto
    &-title
        margin-bottom: 20px
        color: $white
        line-height: 26px
        font:
            size: 20px
            weight: $font-weight-bold
        @include media-breakpoint-up(lg)
            line-height: 39px
            font-size: 32px
            margin-bottom: 40px
    &-gradient
        background: linear-gradient(180deg, #18375F 0%, #04638F 31.25%, #1EB898 72.4%, #FFFFFF 100%)
    img
        display: block
        margin: 0 auto
        max-width: 100%

.hidden-frame
    width: 0
    height: 0
    display: none
    visibility: hidden